.lang_parent {
  width: 80%;
  margin: 0 auto;

  .lang_selector {
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
    padding: 0;

    .selector {
      border: 1px solid var(--color-grey);
      border-radius: 50px;
      padding: 1rem 1.5rem;
      background: var(--color-white);
      color: var(--color-brown);

      span {
        color: var(--color-orange);
        font-weight: 900;
        margin-right: 1rem;
      }

      &.dropArabic {
        // span {
        //   margin-right: inherit;
        //   margin-left: 1rem;
        // }
        display: flex;
        flex-direction: row-reverse;
        text-align: end;

        & span{
          margin-right: 0;
          margin-left: 1rem;
        }
      }

      &.selected {
        background: var(--color-green);
        color: var(--color-white);

        span {
          color: var(--color-white);
        }
      }
    }
  }
}
