.parent {
  position: relative;

  .currentLang {
    border: 2px solid var(--color-light-grey);
    padding: 10px;
    border-radius: 50px;
    position: relative;
    display: flex;
    gap: 5px;
    align-items: center;
    text-transform: uppercase;

    img {
      width: 15px;
      height: 15px;
      transform: rotate(180deg);
      transition: transform 0.3s ease-in-out;

      &.open {
        transform: rotate(0deg);
      }
    }
  }

  .dropdown {
    position: absolute;
    top: 150%;
    right: 0;
    background-color: var(--color-white);
    flex-direction: column;
    gap: 10px;
    z-index: 1;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    animation: fadeIn 0.15s ease-in-out forwards;

    & div{
      overflow-y: scroll;
      max-height: calc(100vh - 135px); //Taille du header
      border-radius: 10px;
    }

    &.closing {
      animation: fadeOut 0.15s ease-in-out forwards;
    }

    &::before {
      content: '';
      position: absolute;
      top: -10px;
      right: 20px;
      width: 20px;
      height: 20px;
      background-color: var(--color-orange);
      display: block;
      transform: rotate(45deg);
    }

    .dropdownItem {
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 15px;
      position: relative;
      z-index: 1;
      background-color: var(--color-white);
      color: var(--color-brown);

      [dir=rtl] & {
        flex-direction: row-reverse;
        text-align: end;
      }

      [dir=ltr] & {
        flex-direction: row;
        text-align: start;
      }

      &.dropArabic {
        [dir=rtl] & {
          flex-direction: row;
        }
  
        [dir=ltr] & {
          flex-direction: row-reverse;
        }
      }

      span {
        font-weight: 900;
      }

      &:first-of-type {
        border-radius: 10px 10px 0 0;
      }

      &:last-of-type {
        border-radius: 0 0 10px 10px;
      }

      &.selected {
        background-color: var(--color-orange);
        color: var(--color-white);
      }
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
