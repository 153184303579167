$orange: #eb6a0a;
$green: #27c682;
$red: #e64747;
$brown: #796f68;
$white: #ffffff;
$grey: #707070;
$light_grey: #efefef;
$black: #000000;

:root {
  --color-orange: #eb6a0a;
  --color-green: #27c682;
  --color-red: #e64747;
  --color-brown: #796f68;
  --color-white: #ffffff;
  --color-grey: #707070;
  --color-light-grey: #efefef;
  --color-black: #000000;
}
