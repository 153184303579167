
.closeBtn {
  position: absolute;
  padding: 1rem;
  z-index: 23;
  background: var(--color-orange);
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 30px;
  right: 30px;

  svg {
    width: 25px;
    height: 25px;
  }
}