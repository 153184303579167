.Button {
  background-color: var(--color-orange);
  width: 100%;
  border-radius: 50px;
  padding: 1rem 2rem;
  border: none;
  color: var(--color-white);
  font-weight: 900;
  font-size: 1rem;
  text-transform: uppercase;
  text-decoration: none;

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
}
