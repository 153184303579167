.burger__menu {
  position: fixed;
  top: 80px;
  left: 0;
  right: 0;
  height: calc(100vh - 80px);
  // height: calc(100dvh - 80px);
  background: var(--color-orange);
  z-index: -1;
  display: flex;
  flex-direction: column-reverse;
  animation: fadeIn 0.3s ease-in-out forwards;
  box-sizing: border-box;
  justify-content: space-around;
  padding-top: 36px;

    
  @media (orientation: landscape) {
    height: calc(100vw - 80px);
    height: calc(100dvw - 80px);
  }

  div {
    overflow-y: auto;
  }

  ul {
    list-style: none;
    padding: 0 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // height: 100%;
    gap: 4rem;

    li {
      text-transform: uppercase;
      color: var(--color-white);
      font-weight: 900;
      font-size: 1.5rem;
      text-align: center;
    }
  }

  &.closing {
    animation: fadeOut 0.3s ease-in-out forwards;
  }
}

.burger {
  transition: transform 0.3s ease;

  .burger__line {
    display: block;
    width: 30px;
    height: 3px;
    background: var(--color-orange);
    margin: 7px 0;
    border-radius: 5px;
  }

  &.open {
    transform: rotate(-90deg);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
