@import './colors.scss';
@import './fonts.scss';
@import './arabic.scss';

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p,
button,
input,
textarea,
label,
a,
div,
span,
li,
ul,
ol,
b,
i,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Lato', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
}

// :root {
  // width: 100%;
  // height: 100%;
// }

// code {
//   font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
// }

html {
  height: -webkit-fill-available;
}
@supports (-webkit-touch-callout: none) {
  body {
    /* The hack for Safari */
    min-height: -webkit-fill-available;
  }
}
body {
  width: 100vw;
  height: 100vh;
  height: 100dvh;

  @media (orientation: landscape) {
    overflow-y: hidden;
  }

  @media (orientation: portrait) {
    overflow-x: hidden;
  }
}

.App {
  position: relative;
  display: flex;
  flex-direction: column;


  @media (orientation: portrait) {
    min-height: 100vh;
    min-height: 100dvh;
    width: 100vw;
  }
  
  @media (orientation: landscape) {
    width: 100vh;
    width: 100dvh;
    min-height: 100vw;
    transform: rotate(-90deg) translateX(-100%);
    transform-origin: top left;
  }
  

  .App_Content {
    padding: 0 30px;
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
  }
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}