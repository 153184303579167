.loader {
  width: 100vw;
  height: 100vh;
  background-color: var(--color-orange);
  display: flex;
  flex-direction: column;
  position: fixed;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  gap: 2rem;

  h2 {
    text-align: center;
    color: var(--color-white);
    font-size: 2rem;
    padding: 30px;
  }

  .flower {
    width: 200vw;
    height: 200px;

    path {
      @media (prefers-reduced-motion: no-preference) {
        animation: leaf_opacity 2s ease-in-out infinite;
      }

      @for $i from 1 through 5 {
        &:nth-of-type(#{$i}) {
          @media (prefers-reduced-motion: reduce) {
            opacity: calc(1 - (0.15 * $i));
          }

          animation-delay: 0.4s * $i;
        }
      }
    }
  }
}

@keyframes leaf_opacity {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.25;
  }
  100% {
    opacity: 1;
  }
}
