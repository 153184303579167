.modal {
  &.modalHeader{
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    position: fixed;

    display: flex;
    align-items: center;

    .backdrop {
      width: 100%;
      height: 100%;
      position: sticky;
    }

    .modal_content{
      position: absolute;
    }
  }

  .backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 49;    
  }

  .modal_content {
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
    left: 30px;
    right: 30px;
    background-color: var(--color-white);
    padding: 2rem;
    border-radius: 1rem;
    box-shadow: 0px 0px 0.625rem rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    z-index: 50;
    opacity: 0;
    animation: fade-in 0.6s ease-in-out forwards;

    &.closing {
      animation: fade-out 0.6s ease-in-out forwards;
    }

    .close {
      background: none;
      border: none;
      position: absolute;
      top: 5px;
      right: 5px;
      margin: 0;

      img {
        width: 20px;
        height: 20px;
      }
    }
    .image {
      position: relative;
      margin: auto;
      margin-bottom: 1rem;
      width: fit-content;

      img {
        border-radius: 50%;
        width: 150px;
        height: 150px;
      }

      p {
        position: absolute;
        width: 20px;
        height: 20px;
        top: 5px;
        right: 0;
        padding: 5px;
        background-color: var(--color-orange);
        color: var(--color-white);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        font-style: italic;
      }
    }

    p {
      font-style: italic;
      margin: 0;
    }

    h4 {
      text-transform: uppercase;
      margin: 5px 0;
    }

    button {
      background: var(--color-orange);
      border: none;
      border-radius: 50px;
      padding: 1rem;
      padding-right: 2rem;
      color: var(--color-white);
      text-transform: uppercase;
      font-weight: bold;
      font-size: 1rem;
      margin: 1rem auto 0 auto;
      display: flex;
      align-self: center;
      gap: 0.5rem;

      img {
        width: 20px;
        height: 20px;
      }
    }
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
