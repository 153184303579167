// switch width
$btnBase: 50px;

.container {
  position: absolute;
  left: 0;
  bottom: 0px;
  right: 0;
  top: -56px;
  overflow: hidden;
}

.video { 
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.canvas { 
  position: absolute;
  top: 0;
  left: 50%;
  //width: 200%;
  height: 100%;
  transform: translateX(-50%);
}

.mirror {
  transform: scaleX(-1) translateX(50%);
}

.switchBtn {
  position: absolute;
  right: 0.4 * $btnBase;
  top: $btnBase;
  width: $btnBase;
  height: $btnBase;
  border-radius: 50%;
  background: white url(../../imgs/icons/switch.svg) no-repeat;
  background-position: center center;
  background-size: 100%;
  z-index: 9;
}

.controls {
  position: fixed;
  bottom: 0.56 * $btnBase;
  left: 0.4 * $btnBase;// calc(50% - 0.8 * $btnBase);
  right: 0.4 * $btnBase;
  z-index: 9;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  & > * {
    flex-grow: 0;
    flex-shrink: 0;
  }
}

// .controls {
//   position: fixed;
//   bottom: 0.56 * $btnBase;
//   left: 0.4 * $btnBase;// calc(50% - 0.8 * $btnBase);
//   right: 0.4 * $btnBase;
//   z-index: 9;
//   display: flex;
//   flex-direction: row;
//   align-items: center;
//   justify-content: flex-start;

//   & > * {
//     flex-grow: 0;
//     flex-shrink: 0;
//   }
// }


.camBtn {
  // transform: translateX(-50%);
  // background: var(--color-orange);
  padding: 2px;
  border: 10px solid white;
  width: calc(1.6 * $btnBase + 24px);
  height: calc(1.6 * $btnBase + 24px);
  position: relative;

  &:before {
    content: '';
    display: block;
    border: 2px solid var(--color-orange);
    width: 1.6 * $btnBase;
    height: 1.6 * $btnBase;
    border-radius: 50%;
    position: absolute;
    z-index: 1;
    top: 0px;
    left: 0px;
  }
}

.controlsContainer {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;

  [dir=rtl] & {
    margin-right: calc(50% - calc(1.6 * $btnBase + 24px) / 2);
  }

  [dir=ltr] & {
    margin-left: calc(50% - calc(1.6 * $btnBase + 24px) / 2);
  }
}

.packBtns {
  // padding-left: calc(50% - .8 * $btnBase - 2px - 10px - 20px);

  button:nth-child(2n) {
    width: $btnBase;
    height: $btnBase;
  }
  button:nth-child(3n) {
    width: 0.7 * $btnBase;
    height: 0.7 * $btnBase;
  }

  button {
    background: white;
    border-radius: 50%;
    // margin-right: 1rem;
    overflow: hidden;
    img {
      width: 80%;
    }
  }
}

.pack1Btn {
  padding-top: 0.25 * $btnBase;
}
.pack2Btn {
  padding-top: 0.13 * $btnBase;
}


button {
  border: none;
}

.savedBtn,
.downloadBtn {
  display: flex;
  justify-content: center;
  button, a {
    width: initial;
    
    background-image: url('../../imgs/icons/download.svg');
    background-repeat: no-repeat;
    background-position: 2em center;
    background-size: 1.5em;
    padding-left: 5.7em;
    padding-right: 5.3em;
  }
}

.savedBtn button, a {
  background-color: var(--color-green);
  background-image: url('../../imgs/icons/check.svg');
}

.closeBtn {
  right: 0.4 * $btnBase !important;
  top: $btnBase !important;
  z-index: 9;
}