.header {
  position: sticky;
  top: 0;
  display: flex;
  justify-content: space-between;
  height: 135px;
  width: 100%;
  z-index: 10;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  
  .wave {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    clip-path: polygon(
      100% 0%,
      0% 0%,
      0% 60.66%,
      2% 60.31%,
      4% 60.02%,
      6% 59.77%,
      8% 59.58%,
      10% 59.44%,
      12% 59.36%,
      14% 59.33%,
      16% 59.36%,
      18% 59.45%,
      20% 59.6%,
      22% 59.8%,
      24% 60.05%,
      26% 60.35%,
      28% 60.7%,
      30% 61.1%,
      32% 61.54%,
      34% 62.02%,
      36% 62.54%,
      38% 63.08%,
      40% 63.66%,
      42% 64.26%,
      44% 64.88%,
      46% 65.51%,
      48% 66.15%,
      50% 66.79%,
      52% 67.44%,
      54% 68.08%,
      56% 68.7%,
      58% 69.31%,
      60% 69.9%,
      62% 70.47%,
      64% 71.01%,
      66% 71.51%,
      68% 71.97%,
      70% 72.4%,
      72% 72.78%,
      74% 73.11%,
      76% 73.39%,
      78% 73.62%,
      80% 73.8%,
      82% 73.92%,
      84% 73.99%,
      86% 74%,
      88% 73.95%,
      90% 73.85%,
      92% 73.69%,
      94% 73.47%,
      96% 73.21%,
      98% 72.89%,
      100% 72.52%
    );
    background-color: var(--color-white);
  }

  > img {
    position: absolute;
    height: 100px;
    width: 100px;
    left: 50%;
    top: 2rem;
    transform: translateX(-50%);
  }

  .content {
    display: flex;
    flex-direction: row-reverse;
    padding: 2rem 1rem 4rem 1rem;
    height: fit-content;
    flex-grow: 1;
    justify-content: space-between;
    position: relative;
  }
}
